import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LightboxImage = ({ localFile }) => (
  <a
    className="with-image"
    href={localFile.childImageSharp.fluid.src}
    data-lightbox="main"
  >
    <Image fluid={localFile.childImageSharp.fluid} />
  </a>
)

const configureActionSelect = dom => {
  window
    .$(dom)
    .find('input[name="action"]')
    .change(function() {
      window
        .$(this)
        .closest("form")
        .find(".af-step-next button")
        .each(function() {
          window.$(this).click()
        })
    })
}

const PropertyTemplate = ({ data, pageContext }) => {
  const arenguFormRef = useRef()

  const showContactPopup = () => {
    if (typeof window.ArenguModal !== "undefined") {
      window.ArenguModal.show(data.formId).then(() => {
        configureActionSelect(window.ArenguModal.ui.modalBody)
      })
    }
  }

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const $sidebar = window.$("#property-contact")
    const $mobileButton = window.$("#property-contact-mobile")
    const $images = window.$("#images")
    const doc = document.documentElement

    const checkScroll = () => {
      const imagesTop = $images.offset().top
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

      if (top + window.innerHeight > imagesTop) {
        showSidebar()
        window.removeEventListener("scroll", checkScroll)
      }
    }

    const showSidebar = () => {
      if (!$sidebar.hasClass("opacity-0")) {
        return
      }

      $sidebar.toggleClass("opacity-0 opacity-100")
      $mobileButton.toggleClass("shown")
      $sidebar.stickr({
        duration: 0,
        offsetTop: 80,
        offsetBottom: 0,
      })
    }

    window.addEventListener("scroll", checkScroll)

    const setupArenguForm = () => {
      window.ArenguForms.embed(data.formId, arenguFormRef.current).then(
        function() {
          configureActionSelect(arenguFormRef.current)
        }
      )
    }

    if (typeof window.ArenguForms === "undefined") {
      window.document.addEventListener("af-init", setupArenguForm)
    } else {
      setupArenguForm()
    }

    setTimeout(showSidebar, 20000)

    return () => {
      window.removeEventListener("scroll", checkScroll)
      window.document.removeEventListener("af-init", setupArenguForm)
    }
  }, [])

  return (
    <Layout
      lang={pageContext.lang}
      alternateLang={pageContext.alternateLang}
      whatsappText={
        data.propertyWhatsappContactText
          ? data.propertyWhatsappContactText.replace(
              "{url}",
              pageContext.pageUrl
            )
          : null
      }
    >
      <SEO
        lang={pageContext.lang}
        path={pageContext.pageUrl}
        title={`${data.airtable.data.TitleTrans}, ${data.airtable.data.Location}`}
        description={`${data.airtable.fields.subtitleTrans}. ${
          data.airtable.data.BedroomsTrans
        }${
          data.airtable.data.BedroomsTrans && data.airtable.data.Surface
            ? " — "
            : ""
        }${
          data.airtable.data.Surface
            ? `${data.airtable.data.SurfaceTrans} m²`
            : ""
        }.`}
        image={
          data.airtable.data.FeaturedImage &&
          data.airtable.data.FeaturedImage.childImageSharp
            ? data.airtable.data.FeaturedImage.childImageSharp.resize.src
            : null
        }
      />
      <div id="header" className="for-proj">
        <div className="section-content">
          <div className="big-text">
            <h1 className="t-left f-large">
              {!!data.airtable.data.BannerTrans && (
                <span className="properties-banner-inline">
                  {data.airtable.data.BannerTrans}{" "}
                </span>
              )}
              {data.airtable.data.TitleTrans}
            </h1>
            <div className="separator-small"></div>
            <h2 className="t-left f-medium normal">
              {data.airtable.fields.subtitleTrans}
            </h2>
            <ul className="t-left f-medium normal">
              {data.airtable.data.BedroomsTrans ||
              data.airtable.data.Surface ? (
                <li>
                  {data.airtable.data.BedroomsTrans}
                  {data.airtable.data.BedroomsTrans &&
                  data.airtable.data.Surface
                    ? " — "
                    : ""}
                  {data.airtable.data.Surface
                    ? `${data.airtable.data.SurfaceTrans} m²`
                    : ""}
                </li>
              ) : null}
              {data.airtable.data.PriceTrans ? (
                <li className="extrabold">{data.airtable.data.PriceTrans} €</li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div id="project">
        <div className="section-content section-content-wide-tablet">
          <div className="row display-flex">
            <div className="col-md-8 main-property">
              <div className="brief no-margin position-relative">
                {data.airtable.data.Images.localFiles.length ? (
                  <LightboxImage
                    localFile={
                      data.airtable.data.Images.localFiles[
                        data.airtable.data.Images.localFiles.length - 1
                      ]
                    }
                  />
                ) : null}
                {!!data.airtable.data.BannerTrans && (
                  <span className="properties-banner f-big">
                    {data.airtable.data.BannerTrans}
                  </span>
                )}
              </div>
              <div className="brief proj">
                <h3 className="t-left f-normal ultrabold uppercase">
                  {data.descriptionText}:
                </h3>
                <div className="separator-small"></div>
                <p
                  className="t-left"
                  dangerouslySetInnerHTML={{
                    __html: [
                      data.airtable.data.BannerTrans
                        ? `<span class="properties-banner-inline">${data.airtable.data.BannerTrans}</span>`
                        : null,
                      data.airtable.data.DescriptionTrans,
                    ]
                      .filter(i => !!i)
                      .join(" "),
                  }}
                />
              </div>
              <div className="brief proj">
                <h3 className="t-left f-normal ultrabold uppercase">
                  {data.featuresText}:
                </h3>
                <div className="separator-small"></div>
                <ul className="t-left">
                  {data.airtable.data.FeaturesTrans.map((feature, k) => (
                    <li key={k}>{feature}</li>
                  ))}
                </ul>
                <div className="separator-small"></div>
                <p className="t-left f-small">
                  {data.locationText}:{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                      data.airtable.fields.locationTrans
                    )}`}
                    className="f-small normal"
                  >
                    {data.airtable.fields.locationTrans}
                  </a>
                </p>
                {data.airtable.data.Price ? (
                  <p className="t-left f-small">
                    {data.priceText}:{" "}
                    <span className="f-small bold a">
                      {data.airtable.data.PriceTrans} €
                    </span>
                  </p>
                ) : null}
              </div>
              <div id="images" className="brief for-images proj">
                {data.airtable.data.Images.localFiles
                  .reverse()
                  .filter((img, k) => k !== 0)
                  .map((img, k) => (
                    <LightboxImage key={k} localFile={img} />
                  ))}
              </div>
            </div>
            <div className="col-md-4 contact-sidebar hidden-xs hidden-sm proj">
              <div id="property-contact" className="opacity-0">
                <div ref={arenguFormRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="property-contact-mobile"
        className="btn-floating-cont hidden-md hidden-lg text-right"
      >
        <button
          type="button"
          className="btn btn-primary btn-block btn-floating"
          onClick={showContactPopup}
        >
          {data.interestedCtaText}
        </button>
      </div>
    </Layout>
  )
}

export default PropertyTemplate

export const pageQuery = graphql`
  query PropertyTemplate($id: String!, $lang: String!) {
    descriptionText: getTranslation(lang: $lang, key: "property_description")
    featuresText: getTranslation(lang: $lang, key: "property_features")
    priceText: getTranslation(lang: $lang, key: "property_price")
    locationText: getTranslation(lang: $lang, key: "property_location")
    interestedCtaText: getTranslation(
      lang: $lang
      key: "property_interested_cta"
    )
    formId: getTranslation(lang: $lang, key: "property_interested_form_id")
    propertyWhatsappContactText: getTranslation(
      lang: $lang
      key: "property_whatsapp_contact_text"
    )
    airtable(id: { eq: $id }) {
      fields {
        locationTrans(lang: $lang)
        subtitleTrans(lang: $lang)
      }
      data {
        TitleTrans(lang: $lang)
        BannerTrans(lang: $lang)
        Location
        DescriptionTrans(lang: $lang)
        FeaturesTrans(lang: $lang)
        PriceTrans(lang: $lang)
        Reference
        SurfaceTrans(lang: $lang)
        BedroomsTrans(lang: $lang)
        FeaturedImage {
          childImageSharp {
            resize(width: 1200, height: 630) {
              src
            }
          }
        }
        Images {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
